.navbar{
    font-display: flex;
    flex-direction: column;
    padding-top: 2rem;
    transition: all 300ms each;
    font-size: 20px;
    font-family: 'Roboto', Arial, sans-serif;
}

.logo{
color: #000;
margin-bottom: 40px;
margin-left: 50px;
margin-top: 30px;
}

.list{
    display:flex;
   gap: 2rem;
    margin: top 2rem;
  flex-direction: column;

}

.list-item{
display:flex;
align-items: center;
gap: 1rem;
height: 2.5rem;
margin-left: 2rem;
position: relative;
transition: all 300ms  ease;
cursor: pointer;
border: 15px;


}


.selected{
  margin-left: 0;
  background: #e1004e;
 
  margin-left: 0;
  border-radius: 8px;

  


}
.selected::before{
    content: '';
    width: 8px;
    height: 100%;
    background: white;
    filter: drop-shadow( 2px 4px 6px white);
    margin-right:  calc(1rem - 8px) ;
}
.main-img{
  width: 150px;
  height: auto;

}
.title-home{
  margin-top:5px;
}