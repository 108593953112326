.right{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    margin-right:30px;
}
.title-updates{
    margin-top: 70px;
    margin-bottom: 10px;
}
.title-r{
margin-bottom: 10px;
margin-top:80px;
}