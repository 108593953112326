.MuiTableRow-root>*{
    padding: 10px;

}
.MuiTableRow-root.MuiTableRow-head>*{
    font-weight: bold!important;
}
.Table td, th{
    border: none!important;
}

.Table:first-child{
    border-radius: 0.7rem!important;
}

.status{
    padding:8px;
    border-radius: 9px;
}
.MuiTable-root{
    background-color:hsl(256, 27%, 11%)!important;
    color: white;;
}


  
  .order .MuiTableCell-root {
    color: white;
  }
  .order{
    margin-top: 20px;
  }
  .order >h3{
    margin-bottom: 30px;

  }