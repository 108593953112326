.App {
  background-color: #e1004e;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.big-container {
  overflow: hidden;
  grid-template-columns: 16rem auto 24rem;
  background-color: #272334;
  display: grid;
  height: 96%;
  width: 97%;
  border-radius: 25px;
  gap: 30px;
}

.mobile-message {
  background-color: #e1004e;
  color: white;
  text-align: center;
  padding: 20px;
  width: 90%;
  border-radius: 8px;
  margin-top: 20px;
}

.main-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 100vh;
  color: white;
}

.mobile-title {
  margin-bottom: 10px;
  font-size: 32px;
}

.main-img {
  max-width: 80%;
  height: auto;
}
