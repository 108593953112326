.Panels{
    display: flex;
    gap: 20px;


}


.parent{
width: 100%;
}
