.updates-container {
    width: 90%;
    max-width: 700px;
    background: hsl(256, 27%, 11%);
    border-radius: 0.7rem;
    padding: 1rem;
    box-shadow: 0px 13px 20px 0px black;
    font-size: 14px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.update-card {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    background: hsl(256, 27%, 15%);
    border-radius: 0.5rem;
    padding: 0.6rem;
    transition: background 0.3s;
}

.update-card:hover {
    background: hsl(256, 27%, 20%);
}

.update-img {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid hsl(256, 27%, 30%);
}

.update-info {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.update-header {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.update-name,
.update-notification,
.update-time {
    color: #fff;
}

.update-name {
    font-weight: bold;
}

.update-notification {
    color: hsl(256, 27%, 80%);
}

.update-time {
    font-size: 12px;
}
